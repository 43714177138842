<template>
	<div>
		<div class="contact">
			<div class="contact-left"><img :src="contactImg" width="120px" height="120px"></div>
			<div class="contact-right">
				<div class="phone">{{phone}}</div>
				<div class="phpone-text">{{$t("userService.phoneText")}}</div>
			</div>
		</div>
		<div class="aboutus">
			<h2>{{$t("userService.aboutUs")}}</h2>
			<p>{{$lang(aboutUsContent,"auc")}}</p>
		</div>
		<div style="height:200px;"></div>
		<div class="field">
			<h2>{{$t("userService.field")}}</h2>
			<p>{{$lang(fieldContent,"fc")}}</p>
		</div>
		
	</div>
</template>

<script>
	export default{
		components: {menuLeft,navTop},
		data(){
			return{
				menuIndex:'5',
				contactImg:"https://",
				
				phone:'16689797559',
				
				phoneText:{
					ptZhCn:'可拨打电话，联系平台主管或平台管理人员',
					ptEnUs:'You can call the platform supervisor or platform manager'
				},
				
				aboutUsContent:{
					aucZhCn:'',
					aucEnUs:''
				},
				
				fieldContent:{
					fcZhCn:'',
					fcEnUs:''
				}
			}
		},
		methods:{
			
		}
	}
</script>

<style>
	.wrap{
		margin: 0 auto;
		width:1200px;
		display: flex;
		justify-content: space-between;
	}
	.wrap-left{
		width:20%;
		min-height:800px;
		background-color: #fff;
		padding:20px 15px 40px;
	}
	.wrap-right{
		width:78%;
		min-height:800px;
		background-color: #fff;
		padding:40px;
		font-size: 14px;
	}
	.contact{
		display: flex;
		justify-content: space-between;
	}
	.contact-left{
		width: 100px;
		padding:0px 0px 40px 0px;
	}
	.contact-right{
		text-align: left;
		width:720px;
	}
	.phone{
		font-size: 24px;
		font-weight: 600;
		color:#3A8EE6;
		padding:20px 0px;
	}
</style>
